<template>
    <div class="contact">
        <img src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/lianxiwomen.png" class="banner">
        <div class="container_wrap">
            <div class="tip_wrap">
                <div class="title">云格数字</div>
                <div class="subtitle">YUNGE DIGITAL</div>
            </div>
            <div class="content_des">
                <div id="container"></div>
                <div class="right_info">
                    <div>
                        <div style="display: inline-block;">
                            <div class="item_wrap">
                                <img src="@/assets/image/icon/telephone.png">
                                <p class="item_name">咨询热线</p>
                            </div>
                            <div>0591-83852927</div>
                        </div>
                        <div style="display: inline-block; float: right;">
                            <div class="item_wrap">
                                <img src="@/assets/image/icon/email.png">
                                <p class="item_name">企业邮箱</p>
                            </div>
                            <div>fzygsz@163.com</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="item_wrap">
                                <img src="@/assets/image/icon/fax.png">
                                <p class="item_name">传真号码</p>
                            </div>
                            <div>0591-88203961</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="item_wrap">
                                <img src="@/assets/image/icon/position.png">
                                <p class="item_name">公司地址</p>
                            </div>
                            <div>福建省福州市闽侯县上街镇中青大厦17层1706</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="phone_list">
                <img src="@/assets/image/phone1.png">
                <img src="@/assets/image/phone2.png">
                <img src="@/assets/image/phone3.png">
                <img src="@/assets/image/phone4.png">
            </div>
        </div>
        <div class="qrcode_list">
            <div class="qrcode_item">
                <img src="../../assets/image/android.png">
                <h6>安卓APP</h6>
            </div>
            <div class="qrcode_item">
                <img src="../../assets/image/ios.png">
                <h6>苹果APP</h6>
            </div>
            <div class="qrcode_item">
                <img src="../../assets/image/small_program.jpg">
                <h6>微信小程序</h6>
            </div>
            <div class="qrcode_item">
                <img src="../../assets/image/company_wechat.png">
                <h6>企业微信</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    mounted() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        var map = new BMapGL.Map("container")  // 创建地图实例 
        var point = new BMapGL.Point(119.223001,26.048213)  // 创建点坐标 
        var marker = new BMapGL.Marker(point)  // 创建标注   
        map.addOverlay(marker)  // 将标注添加到地图中
        map.centerAndZoom(point, 15)
        map.enableScrollWheelZoom(true)
        var opts = {
            width: 220,  // 信息窗口宽度
            height: 10,  // 信息窗口高度
            title: "福州云格数字科技有限公司"  // 信息窗口标题
        }
        var infoWindow = new BMapGL.InfoWindow("", opts)  // 创建信息窗口对象
        marker.addEventListener("click",function(){  
            map.openInfoWindow(infoWindow,point)  // 打开信息窗口  
        })
    }
}
</script>
<style lang="less" scoped>
.contact {
    display: flex;
    flex-direction: column;
    .banner {
        width: 100%;
        height: 450px;
    }
    .container_wrap {
        width: 1200px;
        height: 900px;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: auto;
        margin-top: -40px;
        .tip_wrap {
            text-align: center;
            .title {
                font-size: 30px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                padding: 100px 0 10px 0;
            }
            .subtitle {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                margin-bottom: 60px;
            }
        }
        .content_des {
            padding: 0 95px;
            height: 360px;
            display: flex;
            #container {
                width: 540px;
                height: 360px;
                float: left;
            }
            .right_info {
                float: left;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding-left: 57px;
                flex: 1;
                .item_wrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    .item_name {
                        margin-left: 18px;
                        font-size: 20px;
                        font-family: PingFang SC;
                        font-weight: bold;
                    }
                }
            }
        }
        .phone_list {
            display: flex;
            justify-content: space-between;
            padding: 0 95px;
            margin-top: 60px;
        }
    }
    .qrcode_list {
        display: flex;
        justify-content: space-around;
        margin: 110px auto 145px;
        width: 1200px;
        .qrcode_item {
            margin: 0 30px;
            text-align: center;
            h6 {
                font-size: 16px;
                color: #333;
                margin: 0;
                font-weight: normal;
                margin-top: 15px;
            }
            img {
                width: 107px;
                height: 107px;
            }
        }
    }
}
</style>
